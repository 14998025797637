.widget-card {
  padding: 19px 20px;
  width: 100%;
  max-width: 398px;
  /* height: 210px; */
  border-radius: 12px;
  box-sizing: border-box;
  position: relative;
  overflow-y: hidden;
}

.wc-recorder > .wc-icons--align:first-child {
  transform: translateY(3.5px);
}

.widget-card--shadow {
  box-shadow: 1px 4px 12px 0.5px rgba(0, 0, 0, 0.16);
}

.widget-card__top {
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget-card__img-wrapper > img {
  width: 112px;
  height: 112px;
  border-radius: 8px;
  margin-right: 12px;
}

.widget-card__details-wrapper {
  padding: 0 12px;
}

.widget-card__details-wrapper > * {
  margin-block: 0;
}

.widget-card__details-wrapper > h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #141332;
}

.widget-card__details-wrapper > p:nth-child(2) {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 132%;
  text-align: center;
  color: #333333;
  margin-top: 12px;
}

.widget-card__details-wrapper > p:nth-child(3) {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 132%;
  text-align: center;
  color: #333333;
  margin-top: 12px;
}

.widget-card__bottom {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.widget-card__bottom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.widget-card__record-btn {
  padding: 8px 16px;
  width: 109px;
  height: 37px;
  border-radius: 8px;
  outline: none;
  border: none;
  margin-right: 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;

  cursor: pointer;
}

.widget-card__record-btn > div {
  width: 14px;
  height: 19.38px;
  margin-right: 12px;
  fill: purple;
}

.widget-card__record-img {
  margin-left: 10px;
}

.widget-card__record-btn--only {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.widget-card__record-btn--only > button {
  margin: 0;
}

.wc-recorder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.wc-recorder svg {
  cursor: pointer;
}

.wc-play {
  margin-right: 16px;
}

.wc-rec-wave {
  position: relative;
  width: 75%;
  margin-right: 12px;
  display: flex;
  align-items: center;
}

.wc-play {
  justify-self: flex-end;
}

.wc-rec-right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.wc-rec-wave img {
  width: 100%;
}

.wc-rec-time {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #9b9b9b;
  opacity: 0.8;
  margin-left: 12px;
}

.wave-ref--wrapper {
  position: relative;
  display: inline-block;
}

#wave-ref {
  position: absolute;
  left: 0;
  top: 0;
  margin: auto;
  height: 55px !important;
  /* height: 80px !important; */
  width: 100% !important;
  transform: translateY(1px);
}

#wave-ref > wave wave {
  border-right: 3px solid transparent !important;
}

.wc-player {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wc-player-top {
  display: flex;
  width: 100%;
  align-items: center;
}

.wave-play--wrapper {
  width: 100%;
}

.wc-player--rewind {
  margin-right: 8px;
}

.wc-player--play {
  margin-right: 16px;
  width: 38px;
  height: 38px;
  font-size: 38px;
}

.rec-wave-play {
  height: 100%;
}

.rec-wave-play--wrapper {
  display: flex;
  width: 100%;
  height: 70px;
  align-items: center;
  justify-self: flex-end;
}

.rec-wave-play--wrapper #rec-wave-play {
  width: 212px;
  margin-right: 12px;
}

.rec-wave-play--timer {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #9b9b9b;
  opacity: 0.8;
  width: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wc-player-bottom {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wc-player-bottom > button {
  padding: 8px 16px !important;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.wc-player-bottom > button:nth-child(2) {
  display: flex;
  align-items: center;
  margin-left: 12px;
  inline-size: auto;
}

.wc-player-bottom > button:nth-child(2) > span {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #828282;
  position: relative;
  display: inline-block;
}

.wc-player-bottom > button:nth-child(2) > span:nth-child(2) {
  margin-left: 12px;
}

.wc-player-bottom > button:nth-child(2) > span:nth-child(1) {
  width: 13px;
}

.wc-player-bottom > button:nth-child(2) > span > svg {
  fill: #828282;
  width: 13px;
  height: 18px;
  position: absolute;
  transform: translate(-10px, -10px) !important;
}

.widget-card__submit-form {
  display: flex;
  flex-direction: column;
}

.submit-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 25px;
  box-sizing: border-box;
  /* position: relative; */
}

.submit-form div:nth-child(1) {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-bottom: 30px;
}

.submit-form div:nth-child(1) input {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;

  border: 1px solid #9b9b9b;
  outline: none;
  margin-bottom: 8px;
  border-radius: 6px;
  padding: 8px 5px;
}
.input-default-theme{
  border: 1px solid #5a5a5a !important;
}
.input-default-theme::placeholder {
  color: #5a5a5a !important;
}
.input-custom-theme {
  border-color: #ffffff !important;
}
.input-custom-theme::placeholder {
  color: #ffffff !important;
}
.submit-form button {
  padding: 8px 16px !important;

  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.wave-rec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.wave-rec .wave-ref--wrapper {
  width: 205px;
  height: 40px;
  transform: translateY(-7px);
}

.widget-card__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
}

.widget-card__no-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
}

.auth-tab__error--widget {
  font-size: 12px;
  font-family: "Poppins";
  transform: translateY(-15px);
}

.widget-card input[type="text"],
.widget-card input[type="email"] {
  background-color: transparent;
}

.submit-form--loading {
  height: 212px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 100;
}

.submit-form--loading svg {
  color: grey;
}

.widget-card__watermark {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #828282;
  margin-top: 14px;
}

@media screen and (max-width: 440px) {
  .widget-card {
    padding: 11px 12px;
  }

  .wc-rec-wave {
    width: 80%;
  }

  .wc-player {
    width: 100% !important;
  }

  #rec-wave-play {
    width: 100% !important;
  }
}

@media screen and (max-width: 400px) {
  .widget-card__top {
    flex-direction: column;
    gap: 12px;
  }

  .wc-recorder {
    box-sizing: border-box;
  }

  .wc-rec-wave {
    width: 70%;
  }

  .wc-icons--align > svg {
    width: 33px;
    height: 33px;
  }
}

@media screen and (max-width: 340px) {
  .wc-rec-wave {
    width: 65%;
  }
}

@media screen and (max-width: 310px) {
  .wc-rec-wave {
    width: 60%;
  }

  .wc-player-bottom {
    flex-direction: column;
  }

  .wc-player-bottom > button {
    width: 100%;
  }

  .wc-player-bottom > button:nth-child(2) {
    margin: 0;
    margin-top: 10px;
    justify-content: center;
  }
}
