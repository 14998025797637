.mySwiper {
  margin-top: 20px;
  margin-bottom: 0px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.swiper-slide {
  width: 38% !important;
}

.swiper-slide:nth-child(1) {
  width: 36.12% !important;
}

.swiper-slide:nth-child(2) {
  width: 35.6% !important;
}

.swiper-slide:nth-child(3) {
  width: 45% !important;
  margin-left: -40px !important;
}

.swiper-details {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.swiper-details h1 {
  text-align: center !important;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  width: 100%;
  margin-block: 0;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}

@media screen and (max-width: 1000px) {
  .swiper-details h1 {
    font-size: 24px;
  }
}

@media screen and (max-width: 800px) {
  .swiper-details h1 {
    font-size: 20px;
  }

  .swiper-slide  {
    margin: 0 0 0 20%;
  }

  .swiper-slide {
    width: 60% !important;
  }
  
  .swiper-slide:nth-child(1) {
    width: 60% !important;
  }
  
  .swiper-slide:nth-child(2) {
    width: 55% !important;
    margin-left: 43% !important;
  }
  
  .swiper-slide:nth-child(3) {
    width: 62% !important;
    margin-left: 40% !important;
  }
}

@media screen and (max-width: 340px) {
  .swiper-details h1 {
    font-size: 16px;
  }
}