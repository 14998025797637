.not-found {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.not-found h1 {
  font-size: 200px;
  margin-block: 0 !important;
  margin-top: -50px !important;
}

.not-found p {
  font-size: 24px;
  margin-block: 0 !important;
  margin-top: -10px !important;
  margin-bottom: 60px !important;
}

.not-found .not-found__btns > button:nth-child(1) {
  margin-right: 25px;
}

@media only screen and (max-width: 1008px) {
  .not-found p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 641px) {
  .not-found p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 650px) {
  .not-found p {
    width: 90%;
  }
}

@media only screen and (max-width: 450px) {
  .not-found h1 {
    font-size: 140px;
    margin-top: -20px !important;
  }
}

@media only screen and (max-width: 390px) {
  .not-found .not-found__btns {
    display: flex;
    flex-direction: column;
  }

  .not-found .not-found__btns > button:nth-child(1) {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .not-found p {
    margin-bottom: 40px !important;
  }
}
