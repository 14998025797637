.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.footer hr {
  width: 100% !important;
  border-top: 1px solid #6b69ae;
  margin-bottom: 28px;
}

.footer-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-actions--link * {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #e0e0e0;
  margin-right: 30px;
  text-decoration: none;
}

.footer-actions--icon svg {
  opacity: 0.6;
  color: #ffffff;
}

@media only screen and (max-width: 700px) {
  .footer-actions--link {
    display: flex;
    flex-direction: column !important;
  }

  .footer-actions--link a {
    margin: 0;
    margin-top: 12px;
  }
}
