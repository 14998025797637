.all-settings {
  width: 391px;
}

.all-settings__heading {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #141332;
}

.general-section-input {
  display: flex;
  flex-direction: column;
}

.general-section-input input {
  padding: 10px 16px;
  margin-bottom: 16px;
  padding: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #141332;
  background: #f2f2f2;
  border-radius: 8px;
  border: none;
  outline: none;
}

.account-linking-section__btns {
  margin-top: 8px;
}

.subscription-section__content-tiers {
  display: flex;
  align-items: center;
}

.subscription-section__content-tiers span {
  margin-right: 16px;
}

.subscription-section__content-pricing {
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3864ff !important;
  margin-top: 24px !important;
}

.subscription-section__content-selection {
  display: flex;
  flex-direction: column;
}

.subscription-section__content-selection div:nth-child(1) {
  margin-top: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #141332;
}

.subscription-section__content-selection div:nth-child(2) {
  margin-top: 24px;
}

.billing-section__content {
  display: flex;
  align-items: center;
}

.billing-section__content > div {
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 24px !important;
  margin-right: 14px !important;
}

.billing-section__content > div:nth-child(2) {
  color: #4f4f4f !important;
}

.billing-section__content > div:nth-child(3) {
  color: #141332 !important;
}

.billing-section__content > div:nth-child(4) {
  text-decoration-line: underline;
  cursor: pointer;
  color: #3864ff;
}
.auth-tab__error--alter {
  transform: translateY(-12px);
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.account-linking__linked {
  background-color: #9b9b9b;
  color: #fff;
}

@media only screen and (max-width: 1008px) {
  .all-settings__heading {
    font-size: 20px;
  }
}

@media only screen and (max-width: 641px) {
  .all-settings__heading {
    font-size: 18px;
  }
}

@media only screen and (max-width: 470px) {
  .all-settings {
    width: 100% !important;
  }

  .general-section input {
    width: 100%;
    box-sizing: border-box;
  }

  .billing-section__content {
    flex-wrap: wrap;
  }

  .subscription-section__content-tiers {
    flex-direction: column;
    align-items: flex-start;
  }

  .subscription-section__content-tiers span {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 320px) {
  .account-linking-section__btns > button > span {
    display: none;
  }

  .account-linking-section__btns > button > img {
    margin-right: 0;
  }

  .account-linking-section__btns > button {
    width: 60px;
    margin: 0 !important;
  }

  .account-linking-section__btns {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .subscription-section__content-tiers .tab-navs {
    padding: 4px;
  }

  .subscription-section__content-tiers .tab-navs div {
    padding: 3px 9px;
    font-size: 13px;
  }
}

.subscription-section__info {
  background-color: transparent;
  border: none !important;
  outline: none !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #979797;
  box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 1rem;
    margin-top: 1rem;

}

.subscription-section__unsubscribe {
  display: flex;
  flex-direction: column
}

.subscription-section__unsubscribe div {
  margin-top: 24px;
}
.subscription-section__content-trial {
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3864ff !important;

}