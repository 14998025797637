.app {
  position: relative;
  width: 100%;
  min-height: 100vh;
  height: auto;
  background-color: #141332;
  z-index: 4;
  overflow-x: hidden;
}

.app-circle-1 {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 384px;
  height: 384px;
  border-radius: 200%;
  background-color: #393865;
  transform: translate(-30%, -30%);
  filter: blur(150px);
  -webkit-filter: blur(150px);
  z-index: 5;
}

.app-circle-2 {
  position: absolute !important;
  top: 0;
  right: 0;
  width: 384px;
  height: 384px;
  border-radius: 200%;
  background-color: #393865;
  transform: translate(60%, 90%);
  filter: blur(150px);
  -webkit-filter: blur(150px);
  z-index: 5;
}

.app-content__alignment {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.app-content__alignment > * {
  z-index: 50;
}

.app-content__width-adjust {
  box-sizing: border-box;
  width: 71.25%;
  height: 100%;
  padding: 81px 0;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.app-page-wrapper {
  width: 100%;
  min-height: calc(100vh - 463px);
  margin: 100px 0;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1350px) {
  .app-content__width-adjust {
    width: 85%;
  }
}

@media only screen and (max-width: 1100px) {
  .app-content__width-adjust {
    width: 90%;
  }
}

@media only screen and (max-width: 800px) {
  .app-page-wrapper {
    margin: 70px 0 !important;
    min-height: calc(100vh - 383px) !important;
  }

  .app-content__width-adjust {
    width: 85%;
    padding: 65px 0;
  }
}

@media only screen and (max-width: 750px) {
  .app-page-wrapper {
    margin: 30px 0 !important;
    min-height: calc(100vh - 305px) !important;
  }
}

@media only screen and (max-width: 650px) {
  .app-content__width-adjust {
    width: 92%;
    padding: 65px 0;
  }

  .app-page-wrapper {
    min-height: calc(100vh - 291px) !important;
  }
}

@media only screen and (max-width: 600px) {
  .app-page-wrapper {
    min-height: calc(100vh - 323px) !important;
  }
}

@media only screen and (max-width: 358px) {
  .app-page-wrapper {
    min-height: calc(100vh - 338px) !important;
  }
}
