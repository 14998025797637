.tab-navs {
  box-sizing: border-box;
  padding: 8px;
  background: #f2f2f2;
  border-radius: 12px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.tab-navs button {
  all: unset;
  padding: 4px 14px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #828282;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.tab-navs button:disabled {
  all: unset;
  padding: 4px 14px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #828282;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.tab-navs .tab-nav--active {
  color: #141332 !important;
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.16) !important;
}

.tab-nav--heart {
  color: #BDBDBD;
}
