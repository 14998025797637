.sidebar-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.sidebar-card__voice {
  position: relative;
  height: 47px;
  width: 90%;
  background: #393865;
  border-radius: 14px 14px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 12px;
  box-sizing: border-box;
}

.sidebar-card__voice > .sidebar-card__voice-audio {
  width: 90px;
  height: 30px;
  position: relative;
  overflow: hidden;
  left: -1px;
}

.sidebar-card__voice > .sidebar-card__voice-audio > wave {
  height: 30px !important;
}

.sidebar-card__voice > div:nth-child(1),
.sidebar-card__voice > div:nth-child(3) {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #ffffff;
  opacity: 0.8;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-card__voice > div:nth-child(1) {
  margin-right: 8px;
}

.sidebar-card__voice > div:nth-child(3) {
  margin-left: 8px;
}

.sidebar-card__card {
  height: 130px;
  width: 100%;
  background: linear-gradient(109.46deg, #6b8cff 17.71%, #3d38ff 64.06%);
  border-radius: 14px;
  padding: 16px 20px;
  box-sizing: border-box;
}

.sidebar-card__card-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  max-width: 168px;
  margin-block: 0 !important;
  margin-bottom: 6px;
  overflow-x: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .sidebar-card__card-heading span {
  display: block;
  width: 100%;
  transform: translateX(20px);
  animation: move 15s linear infinite;
} */

@keyframes move {
  to {
    transform: translateX(-100%);
  }
}

.sidebar-card__card-details {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 10px;
}

.sidebar-card__card-details p {
  margin-block: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #ffffff;
  width: auto;
}

.sidebar-card__card-details > div {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #fff;
}

.sidebar-card__card-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-card__card-controls svg {
  width: 32px;
  height: 32px;
}

@media only screen and (max-width: 1008px) {
  .sidebar-card__card-heading {
    font-size: 18px;
  }
}

@media only screen and (max-width: 641px) {
  .sidebar-card__card-heading {
    font-size: 16px;
  }
}
.sidebar-card__text {

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

}
