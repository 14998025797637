.pricing-card {
  background: #ffffff;
  border-radius: 28px;
  width: 70%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  border: none;
  box-shadow: 2px 2px 2px 2px rgba(1, 1, 1, 0.1);
}

.pricing-card--inverted {
  background: #3864ff !important;
}

.pricing-card__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #141332;
  margin-bottom: 12px;
}

.pricing-card__list {
  margin-top: 20px;
}

.pricing-card__list-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #828282;
  margin-bottom: 6px;
}

.pricing-card--inverted .pricing-card__title {
  color: #ffffff !important;
}

.pricing-card__pricing {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #141332;
}

.pricing-card--inverted .pricing-card__pricing {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  color: #ffffff !important;
}

.pricing-card--inverted .pricing-card__list-title {
  color: #94e5ff !important;
}

.pricing-card--inverted .pricing-card__list-small {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  color: #ffffff !important;
}

.pricing-card__list-small {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
}

.pricing-card__list-img {
  color: #3864ff;
}

.pricing-card--inverted .pricing-card__list-img {
  color: #def7ff !important;
}

.pricing-card__pricing-dollar {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  transform: translateY(-5px) !important;
  /* margin-top: -10px; */
  display: inline-block;
}

.pricing-card__list-btn button {
  margin-top: 30px;
  background: #3864ff;
  box-shadow: 0px 4px 12px rgba(56, 100, 255, 0.16);
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}

.pricing-card__list-btn--inverted button {
  background: #ffffff !important;
  box-shadow: 0px 4px 12px rgba(0, 38, 173, 0.32) !important;
  color: #3864ff !important;
}

@media only screen and (max-width: 500px) {
    .pricing-card {
        width: 45%;
    }
}

@media only screen and (max-width: 350px) {
    .pricing-card {
        width: 33%;
    }
}