.audio-stick {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.audio-stick hr {
  width: 100%;
  opacity: 0.2;
  border: 1px solid #3864ff;
  margin-block: 0;
  margin-top: 30px;
  margin-bottom: 30px;
}

.audio-stick__align {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.audio-stick__dets {
  width: 46%;
}

.audio-stick__edit {
  margin-right: 30px;
}

.audio-stick__edit > input[type="checkbox"] {
  width: 25px;
  height: 25px;
}

.audio-stick__dets h2,
.audio-stick__dets p {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-block: 0;
  font-style: normal;
  font-weight: 500;
  color: #141332;
  text-size-adjust: auto;
}

.audio-stick__dets h2 {
  font-size: 20px;
  line-height: 30px;
}

.audio-stick__dets p {
  font-size: 14px;
  line-height: 21px;
  margin: 6px 0;
}

.audio-stick__dets small {
  margin-block: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #828282;
}

.audio-stick__dets-small {
  display: flex;
  align-items: center;
}

.audio-stick__dets-small small {
  white-space: nowrap;
}

.audio-stick-dot {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: #828282;
  margin: 0 8px;
}

.audio-stick__icons {
  display: flex;
  align-items: center;
  width: 130px;
}

.audio-stick__icons svg {
  cursor: pointer;
  font-size: 20px;
  color: #9b9b9b;
  width: 20px;
  height: 20px;
}

.audio-stick__icons svg:not(:last-child) {
  margin-right: 14px;
}

.audio-stick__icons > a {
  margin-right: 14px;
}

.audio-stick__audio {
  width: 42%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.audio-stick__audio .webform-audio {
  justify-content: center;
}

@media only screen and (max-width: 1008px) {
  .audio-stick__dets h2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 641px) {
  .audio-stick__dets h2 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1050px) {
  .audio-stick__icons svg:not(:last-child) {
    margin-right: 7px;
  }
}
