div.accordian-stick {
  margin: 0 !important;
  margin-bottom: 24px !important;
  border-radius: 16px !important;
  background: #1e1d44 !important;
  font-family: "Josefin Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 125% !important;
  letter-spacing: 0.02em !important;
  color: #ffffff !important;
  padding: 24px;
}

div.accordian-stick svg {
  color: #ffffff !important;
}

.accordian-stick__description {
  max-width: 48% !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  margin-top: 10px !important;
  color: #d3d3d3 !important;
  text-align: justify;
}

@media only screen and (max-width: 1008px) {
  div.accordian-stick {
    font-size: 18px;
  }
}

@media only screen and (max-width: 641px) {
  div.accordian-stick {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1180px) {
  .accordian-stick__description {
    max-width: 65% !important;
  }
}

@media only screen and (max-width: 550px) {
  .accordian-stick__description {
    max-width: 75% !important;
  }
}

@media only screen and (max-width: 550px) {
  .accordian-stick__description {
    max-width: 75% !important;
  }
}
