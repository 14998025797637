.pricing-table {
  margin-top: 42px;
  margin-bottom: 48px;
  min-width: 1000px;
  min-height: 685px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricing-table {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricing-table {
  position: relative;
}

.pricing-table__white-bg {
  position: absolute;
  left: 323px;
  top: 35px;
  background-color: #fff;
  width: 427px;
  height: 645px;
  z-index: -1;
  border-top-left-radius: 28px;
  border-bottom-left-radius: 28px;
}

.pricing-table__blue-bg {
  position: absolute;
  left: 745px;
  top: 15px;
  background-color: #3864ff;
  width: 269px;
  height: 685px;
  z-index: -1;
  border-radius: 28px;
}

.pricing-table__vl {
  position: absolute;
  left: 530px;
  top: 55px;
  background-color: #d3d3d3;
  width: 1.5px;
  height: 600px;
}

.pricing-table {
  position: relative;
  z-index: 100;
}

.pricing-table table {
  width: 100%;
}

.pricing-table table tr:nth-child(3) td:not(:first-child),
.pricing-table table tr:nth-child(4) td:not(:first-child),
.pricing-table table tr:nth-child(5) td:not(:first-child) {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
}

.pricing-table table tr:nth-child(3) td:last-child,
.pricing-table table tr:nth-child(4) td:last-child,
.pricing-table table tr:nth-child(5) td:last-child {
  color: #fff;
}

.pricing-table table tr td:not(:first-child),
.pricing-table table tr th:not(:first-child) {
  text-align: center;
}

.pricing-table table tr td:first-child,
.pricing-table table tr th:first-child {
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 320px;
  padding: 20px 0;
}

.pricing-table table tr:nth-child(1) th {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #141332;
  height: 35px;
}

.pricing-table table tr:nth-child(2) th {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #141332;
  height: 35px;
}

.pricing-table table tr th:nth-child(4),
.pricing-table table tr td:nth-child(4) {
  color: #fff;
}

.pricing-table__small {
  position: absolute;
  font-size: 18px;
  transform: translate(-12px, -2px);
}

.pricing-table__mo {
  position: absolute;
  font-size: 15px;

}

@media only screen and (max-width: 1008px) {
  .pricing-table table tr:nth-child(1) th,
  .pricing-table table tr:nth-child(2) th {
    font-size: 24px;
  }
}

@media only screen and (max-width: 641px) {
  .pricing-table table tr:nth-child(1) th,
  .pricing-table table tr:nth-child(2) th {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1100px) {
  .pricing-table {
    min-width: 850px;
  }

  .pricing-table__white-bg {
    left: 290px;
    top: 35px;
    width: 360px;
    height: 645px;
  }

  .pricing-table__blue-bg {
    left: 650px;
    top: 15px;
    width: 190px;
    height: 685px;
  }

  .pricing-table__vl {
    left: 480px;
    top: 55px;
  }
}

@media only screen and (max-width: 715px) {
  .pricing-table {
    margin: 90px 0 60px 0;
    min-width: 350px;
  }

  .pricing-table table tr td:first-child,
  .pricing-table table tr th:first-child {
    width: 200px;
    padding-right: 5px;
  }

  .pricing-table__white-bg {
    left: 200px;
    top: 5px;
    width: 245px;
    height: 700px;
  }

  .pricing-table__blue-bg {
    left: 440px;
    top: -15px;
    width: 160px;
    height: 740px;
  }

  .pricing-table__vl {
    height: 655px;
    left: 300px;
    top: 35px;
  }
}

@media only screen and (max-width: 1008px) {
  .pricing-table table tr:nth-child(3) td:not(:first-child),
  .pricing-table table tr:nth-child(4) td:not(:first-child),
  .pricing-table table tr:nth-child(5) td:not(:first-child),
  .pricing-table__small {
    font-size: 18px;
  }
}

@media only screen and (max-width: 641px) {
  .pricing-table table tr:nth-child(3) td:not(:first-child),
  .pricing-table table tr:nth-child(4) td:not(:first-child),
  .pricing-table table tr:nth-child(5) td:not(:first-child),
  .pricing-table__small {
    font-size: 16px;
  }
}

@media only screen and (max-width: 650px) {
  .pricing-table {
    width: 480px;
    margin: 120px 0 75px 0;
  }

  .pricing-table table tr td:first-child,
  .pricing-table table tr th:first-child {
    width: 100px;
  }

  .pricing-table table tr td:nth-child(3),
  .pricing-table table tr th:nth-child(3) {
    transform: translateX(0px);
  }

  .pricing-table table tr td:nth-child(2),
  .pricing-table table tr th:nth-child(2) {
    transform: translateX(-5px);
  }

  .pricing-table table tr td:nth-child(4),
  .pricing-table table tr th:nth-child(4) {
    transform: translateX(-13px);
  }

  .pricing-table__white-bg {
    left: 100px;
    top: -20px;
    width: 220px;
    height: 900px;
  }

  .pricing-table__blue-bg {
    left: 319px;
    top: -40px;
    width: 140px;
    height: 940px;
  }

  .pricing-table__vl {
    height: 850px;
    left: 200px;
    top: 5px;
  }
}

@media only screen and (max-width: 580px) {
  .pricing-table {
    margin-top: 60px;
    margin-bottom: 10px;
    width: 500px;
    height: 1000px;
    overflow-x: auto;
    overflow-y: visible !important;
  }

  .pricing-table table tr td:nth-child(1),
  .pricing-table table tr th:nth-child(1) {
    width: 70px !important;
    transform: translateX(12px);
  }

  .pricing-table table tr td:nth-child(3),
  .pricing-table table tr th:nth-child(3) {
    transform: translateX(0px);
  }

  .pricing-table table tr td:nth-child(2),
  .pricing-table table tr th:nth-child(2) {
    transform: translateX(30px);
  }

  .pricing-table table tr td:nth-child(4),
  .pricing-table table tr th:nth-child(4) {
    transform: translateX(-42px);
  }

  .pricing-table__white-bg {
    left: 130px;
    top: 60px;
    width: 185px;
    height: 900px;
  }

  .pricing-table__blue-bg {
    left: 315px;
    top: 40px;
    width: 117px;
    height: 940px;
  }

  .pricing-table__vl {
    display: none !important;
  }
}
