.forgot-password {
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgot-password--align {
  width: 50%;
  height: auto;
  background-color: #ffffff !important;
  color: #141332;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 28px;
  padding: 40px;
  box-sizing: border-box;
}

.forgot-password__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forgot-password__form h2 {
  font-size: 20px;
  margin-bottom: 30px;
}

.forgot-password__form input {
  width: 90%;
  padding: 10px 16px;
  background: #f2f2f2;
  border-radius: 8px;
  outline: none;
  border: none;
  color: #141332;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.forgot-password__submit-btn {
  align-self: flex-end;
}

@media only screen and (max-width: 1000px) {
  .forgot-password--align {
    width: 60%;
  }
}

@media only screen and (max-width: 800px) {
  .forgot-password--align {
    width: 75%;
  }

  .forgot-password__form h2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 500px) {
  .forgot-password--align {
    width: 85%;
  }

  .forgot-password__form h2 {
    font-size: 17px;
  }
}

@media only screen and (max-width: 400px) {
  .forgot-password--align {
    width: 92%;
  }

  .forgot-password__form h2 {
    font-size: 17px;
  }

  .forgot-password__submit-btn {
    align-self: center;
  }
}
