.icon-button {
  padding: 12px 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 1px solid #9b9b9b;
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #141332;
}

.icon-button img {
  margin-right: 16px;
}

@media only screen and (max-width: 700px) {
  .icon-button {
    font-size: 14px;
  }
}