.webform-audio {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  width: 92%;
}

.webform-audio__wave {
  width: 50%;
  margin-left: -20px;
  overflow-x: hidden !important;
  transition: all 0.8s ease-in;
}

.webform-audio__wave-playing {
  width: 100%;
}

.webform-pp-icons {
  margin-right: 28px;
  transform: translateY(1px);
  border: none;
  outline: none;
  background-color: transparent;
}

.webform-play-icon {
  font-size: 28px !important;
  color: #bdbdbd;
  cursor: pointer;
}

.webform-pause-icon {
  font-size: 28px !important;
  margin: 0 !important;
  color: #3864ff;
  cursor: pointer;
}

.webform-audio__loading {
  width: 43.6%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.webform-audio__loading > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.webform-audio__number {
  margin-left: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #3864ff;
  opacity: 0.8;
  width: 90px;
  max-width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  transition: all 1s ease-in-out;
}

.webform-audio__number--paused {
  display: none;
}
