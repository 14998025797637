.button-style {
  cursor: pointer !important;
  outline: none;
  padding: 8px 24px;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  outline: none;
  border: none;
}

.button--outlined {
  border: 1.2px solid #e0e0e0 !important;
  filter: drop-shadow(0px 4px 12px rgba(56, 100, 255, 0.16));
  background-color: transparent;
}

.button--filled {
  background: linear-gradient(109.46deg, #6b8cff 17.71%, #3d38ff 64.06%);
  box-shadow: 0px 4px 12px rgba(56, 100, 255, 0.16);
  border-radius: 8px;
}
button:disabled {
  background: #828282;
  box-shadow: 0px 4px 12px rgba(61, 61, 61, 0.16);
  cursor: not-allowed !important;
}

@media only screen and (max-width: 1008px) {
  .button-style {
    font-size: 18px;
  }
}

@media only screen and (max-width: 641px) {
  .button-style {
    font-size: 16px;
  }
}
