.pricing {
  width: 100%;
  margin: -40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: flex-start
}

.pricing--align {
  min-width: 600px;
  min-height: 594px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricing * button:disabled {
  background: grey !important;
  box-shadow: 0px 4px 12px rgba(52, 52, 52, 0.16) !important;
}
.pricing-head {
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 63px;
  color: #ffffff;
  margin-bottom: 47px;
}

.pricing-tab-menu {
  width: 100%;
  height: 100%;
  color: #141332;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricing-tab-menu__cards {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 60px;
  width: 100%;
  align-items: center;
}

.pricing-tab-menu__cards > div:not(:last-child) {
  margin-bottom: 24px;
}

@media only screen and (max-width: 1008px) {
  .pricing-head {
    font-size: 36px;
  }
}

@media only screen and (max-width: 800px) {
  .pricing {
    margin: 60px 0;
  }
}

@media only screen and (max-width: 750px) {
  .pricing {
    margin: 85px 0;
  }
}

@media only screen and (max-width: 641px) {
  .pricing-head {
    font-size: 28px;
  }
}

.animated-button1 {
  background: linear-gradient(-30deg, #1f28a0 50%, #1f19c3 50%);
  padding: 20px 20px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0px 4px 12px rgba(56, 100, 255, 0.16);
  box-shadow: 0px 4px 12px rgba(56, 100, 255, 0.16);
  border-radius: 10px;
}

.animated-button1::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #8885ad;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button1:hover::before {
  opacity: 0.2;
}

.animated-button1 span {
  position: absolute;
}

.animated-button1 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(8, 17, 43, 0)), to(#3d38ff));
  background: linear-gradient(to left, rgba(8, 17, 43, 0), #3d38ff);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button1 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(8, 17, 43, 0)), to(#3d38ff));
  background: linear-gradient(to top, rgba(8, 17, 43, 0), #3d38ff);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button1 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(8, 17, 43, 0)), to(#3d38ff));
  background: linear-gradient(to right, rgba(8, 17, 43, 0), #3d38ff);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button1 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(8, 17, 43, 0)), to(#3d38ff));
  background: linear-gradient(to bottom, rgba(8, 17, 43, 0), #3d38ff);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
