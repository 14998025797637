.account {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.account-right {
  width: 100%;
  background: #ffffff;
  border-radius: 28px;
  padding: 33px;
  color: #000;
}

.all-recs {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.all-recs__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.all-recs__head > div {
  display: flex;
  align-items: center;
}

.all-recs__head > div:nth-child(1) h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  margin-right: 20px;
  color: #9b9b9b;
}

.all-recs__head > div:nth-child(1) > div {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #9b9b9b;
  margin-left: 6px;
}

.all-recs__head > div:nth-child(2) {
  display: flex;
  align-items: center;
}

.all-recs__head > div:nth-child(2) > svg {
  color: #9b9b9b;
  margin-right: 21px;
}

.all-recs__head > div:nth-child(2) > div:nth-child(2) > button {
  padding: 8px 24px;
  min-width: 74px;
  min-height: 38px;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 12px;
  margin-right: 12px;
  cursor: pointer;
}

.all-recs__head > div:nth-child(2) > div:nth-child(3) > .tab-navs {
  height: 38px !important;
}

.all-recs__heading {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 27vw;
}

.all-recs > ::-webkit-scrollbar {
  width: 4px !important;
}

.all-recs > ::-webkit-scrollbar-thumb {
  background: #bdbdbd !important;
  border-radius: 10px;
  cursor: pointer;
}

.all-recs__search {
  display: flex;
  margin-right: 15px;
  position: relative;
  align-items: center;
  transition: all 0.8s ease-in-out;
}

.all-recs__search > svg {
  color: #9b9b9b;
}

.all-recs__search > input {
  outline: none;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 12px;
  padding: 10px 24px 10px 40px;
  width: 200px;
  box-sizing: border-box;
  font-size: 16px;
  color: #9b9b9b;
  font-family: "Poppins", sans-serif;
  transition: all 0.8s ease-in-out;
}

.all-recs__search svg:nth-child(1) {
  transform: translateX(35px);
}

.all-recs__search svg:nth-child(3) {
  position: absolute;
  transform: translateX(195px);
  cursor: pointer;
}

.account-users {
  display: flex;
  flex-direction: column;
}

.account-users__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.account-users__top > div:nth-child(1) {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #9b9b9b;
}

.account-users__top > div:nth-child(2) {
  display: flex;
  align-items: center;
}

.account-users__top > div:nth-child(2) > svg {
  color: #9b9b9b;
  width: 26px;
  height: 26px;
  margin-right: 22px;
}

.account-users__top > div:nth-child(2) > a > button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  border: 1px solid #9b9b9b;
  border-radius: 8px;
  outline: none;
  background-color: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #828282;
  cursor: pointer;
}

.account-users__top > div:nth-child(2) > a {
  text-decoration: none !important;
}

.account-users__top > div:nth-child(2) > a > button div {
  margin-left: 10px;
}

.account-users__top .all-recs__search {
  margin: 0;
}

.account-users__top > div:nth-child(2) {
  height: 46px;
}

.account-users__bottom {
  margin-top: 26px;
}

.userstick {
  margin-top: 16px;
}

.userstick hr {
  opacity: 0.2;
  border-top: 2px solid #3864ff;
}

.userstick-content {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 16px;
}

.userstick-content > div:nth-child(1) {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #828282;
  margin-right: 17px;
}

.userstick-content > div:nth-child(2) {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #141332;
  margin-right: 12px;
}

.userstick-content > div:nth-child(4) {
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #828282;
  margin-left: 12px;
}

.account-right__loading {
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-small__btn {
  position: fixed;
  top: 200px;
  left: 0;
  cursor: pointer;
  z-index: 1000;
}

.account-small-drawer div:nth-child(3) {
  background-color: #25244a;
}

.all-recs__content {
  min-height: 500px;
}

.all-recs__content > .audio-stick:last-child hr {
  display: none;
}

/* .all-recs__content > .audio-stick:last-child {
  margin-bottom: 40px;
} */

.all-recs--no-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-weight: 500;
  color: #555;
  padding-top: 12px;
}

@media only screen and (max-width: 1700px) {
  .all-recs__heading {
    max-width: 20vw;
  }
}

@media only screen and (max-width: 1460px) {
  .all-recs__heading {
    max-width: 15vw;
  }
}

@media only screen and (max-width: 1350px) {
  .all-recs__heading {
    max-width: 18vw;
  }
}

@media only screen and (max-width: 1120px) {
  .all-recs__heading {
    max-width: 30vw;
  }
}

@media only screen and (max-width: 1050px) {
  .account-right {
    padding: 20px;
    min-height: auto !important;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 1008px) {
  .all-recs__head > div:nth-child(1) h3,
  .account-users__top > div:nth-child(1) {
    font-size: 20px;
  }

  .all-recs__head > div:nth-child(1) > div {
    font-size: 18px;
  }
}

@media only screen and (max-width: 730px) {
  .all-recs__head {
    flex-direction: column;
    margin-bottom: 35px;
    align-items: center;
  }

  .all-recs__heading {
    max-width: 60vw;
  }

  .all-recs__head > div:nth-child(2) > .all-recs__search {
    margin-right: 0;
  }
}

@media only screen and (max-width: 641px) {
  .all-recs__head > div:nth-child(1) h3,
  .account-users__top > div:nth-child(1) {
    font-size: 18px;
  }

  .all-recs__head > div:nth-child(1) > div {
    font-size: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .audio-stick__align {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .all-recs__content .audio-stick__align .audio-stick__audio {
    width: 100%;
  }

  .all-recs__content .audio-stick__align .audio-stick__audio .webform-audio {
    width: 100%;
    justify-content: flex-start;
  }

  .all-recs__content
    .audio-stick__align
    .audio-stick__audio
    .webform-audio__wave {
    width: 70%;
  }

  .all-recs__content .audio-stick__dets {
    width: 70%;
  }

  .all-recs__content .webform-audio__wave-playing {
    width: 50% !important;
  }

  .all-recs__content .webform-audio__number {
    width: 45px;
  }

  .all-recs__content .audio-stick__align .audio-stick__icons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -3%;
  }

  .all-recs__content .audio-stick__align .audio-stick__icons svg {
    margin: 0;
    margin-bottom: 20px;
  }
  .all-recs__content .audio-stick__align .audio-stick__icons a {
    margin: 0;
  }
}

@media only screen and (max-width: 500px) {
  .all-recs__heading {
    max-width: 40vw;
  }
  .all-recs__content .audio-stick__align .audio-stick__icons {
    right: -5%;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .userstick-content {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
  }

  .userstick-content div:nth-child(1) {
    position: absolute;
    top: 28%;
  }

  .userstick-content div:nth-child(2) {
    margin: 0;
    margin-left: 55px;
    max-width: 100%;
    word-break: break-all;
    word-wrap: break-word;
  }

  .userstick-content div:nth-child(3) {
    display: none;
  }

  .userstick-content div:nth-child(4) {
    margin: 0;
    margin-left: 55px;
    max-width: 100%;
    word-break: break-all;
    word-wrap: break-word;
  }
}

@media only screen and (max-width: 450px) {
  .account-users__top {
    flex-direction: column;
  }
}

@media only screen and (max-width: 380px) {
  .all-recs__head > div:nth-child(2) > .all-recs__search input {
    padding: 5px 24px 5px 40px;
    width: 170px;
    transform: translateX(5px);
  }

  .all-recs__head > div:nth-child(2) > .all-recs__search > svg:nth-child(1) {
    position: absolute;
    z-index: 10;
    transform: translateX(15px);
  }

  .all-recs__head > div:nth-child(2) > .all-recs__search > svg:nth-child(3) {
    transform: translateX(145px) !important;
  }

  .all-recs__heading {
    font-size: 16px !important;
    margin-right: 5px !important;
  }

  .account-users__top {
    align-items: center;
  }

  .account-users__top button {
    padding: 7px 8px !important;
  }

  .account-users__top button svg {
    width: 20px;
    height: 100%;
  }
}
