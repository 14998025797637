.faq {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq-head {
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 63px;
  color: #ffffff;
  margin-bottom: 64px;
}

.faq-list {
  width: 100%;
}

@media only screen and (max-width: 1008px) {
  .faq-head {
    font-size: 36px;
  }
}

@media only screen and (max-width: 641px) {
  .faq-head {
    font-size: 28px;
  }
}

@media only screen and (max-width: 650px) {
  .faq-head {
    margin-bottom: 34px;
  }
}

@media only screen and (max-width: 600px) {
  .faq {
    margin: 50px 0;
  }
}
