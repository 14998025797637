.add-project {
  display: flex;
  flex-direction: column;
}

.add-project__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-project__top-actions {
  display: flex;
  align-items: center;
}

.add-project__top-actions > div {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.add-project__top-actions > div svg {
  color: #9b9b9b;
  margin-right: 20px;
}

.add-project__top-actions > h2 {
  margin-block: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #141332;
  margin-right: 20px;
}

.add-project__top-actions>span {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #9b9b9b;
  outline: none;
  border: 1px solid transparent;
  padding: 0 5px;
  border-radius: 5px;
}

.add-project__top-actions>span:focus {
  border: 1px solid #9b9b9b;
  padding: 0 5px;
  border-radius: 5px;
}

.add-project__middle {
  margin-top: 38px;
  margin-bottom: 46px;
  display: flex;
  flex-direction: column;
}

.add-project__middle-dets {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.add-project__middle-inps {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 25px;
}

.add-project__middle-inps input {
  padding: 10px 16px;
  background: #f2f2f2;
  border-radius: 8px;
  margin-bottom: 16px;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #141332;
}

.add-project__middle-inps textarea {
  font-family: "Poppins", sans-serif;
  padding: 10px 16px;
  background: #f2f2f2;
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 132%;
  color: #333333;
  outline: none;
  border: none;
  resize: vertical;
}

.add-project__middle-img {
  display: flex;
  flex-direction: column;
}

.add-project__middle-img__bg {
  display: flex;
}

.add-project__middle-img__bg-wrapper {
  display: inline;
  width: 120px;
  height: 120px;
  margin-right: 18px;
  border-radius: 8px;
}

.add-project__middle-img__bg-wrapper > img {
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.add-project__middle-img__bg-up {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.add-project__middle-img__bg-file {
  border: 1px solid rgb(130, 130, 130);
  border-radius: 5px;
}

.add-project__middle-img__bg-up small {
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #9b9b9b;
}

.add-project__middle-img__bg-up div:nth-child(1) {
  margin-bottom: 10px;
  width: 200px;
  overflow-x: hidden;
  display: flex;
}

.add-project__middle-img__bg-up div:nth-child(1) small {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-project__middle-img__bg-up small:nth-child(3) {
  font-style: normal;
  margin-top: 16px;
}

.add-project__middle-opt {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.add-project__middle-opt small {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-right: 16px;
}

.add-project__middle-opt svg {
  margin-left: 16px;
  cursor: pointer;
}

.add-project__separator {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.add-project__separator hr {
  width: 100%;
  border-top: 1px solid #bdbdbd;
  height: 0;
}

.add-project__separator small {
  position: absolute;
  padding: 0 16px;
  background-color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #828282;
  transform: translateY(-1px);
}

.add-project__bottom {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.add-project__bottom-selections {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.add-project__bottom-selections__embed > p:nth-child(1) {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  margin-block: 0;
}

.add-project__bottom-selections__embed > div {
  margin-top: 8px;
  margin-bottom: 28px;
  padding: 10px 16px;
  width: 357.5px;
  height: 110px;
  background: #f2f2f2;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
}

.add-project__bottom-selections__embed > div > div {
  overflow-y: auto;
  height: 90px;
  width: 315px;
  padding-right: 5px;
}

.add-project__bottom-selections__embed > div > div > pre {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  background: #f2f2f2 !important;
  border: none !important;
  margin: 0 !important;
  box-sizing: border-box;
  overflow-x: hidden !important;
  padding: 0 !important;
  overflow-y: hidden !important;
}

.add-project__bottom-selections__embed > div > div > pre > * {
  word-break: break-all !important;
  font-family: "Poppins" !important;
  font-size: 14px !important;
  line-height: 18px !important;
}

.add-project__bottom-selections__embed ::-webkit-scrollbar {
  width: 4px !important;
  cursor: pointer;
  margin-left: -5px;
}

.add-project__bottom-selections__embed ::-webkit-scrollbar-thumb {
  background: #bdbdbd !important;
  border-radius: 24px;
  cursor: pointer;
}

.add-project__bottom-selections__embed > div > p {
  margin-block: 0;

}

.add-project__bottom-selections__embed > div > p > svg {
  margin-left: 5px;
  cursor: pointer;
}

.add-project__bottom-selections__opts > p:nth-child(1) {
  margin-block: 0;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #9b9b9b;
  margin-bottom: 20px;
}

.cp {
  display: flex;
  align-items: center;
  position: relative;
}

.cp > div:nth-child(1) {
  margin-left: 16px;
  margin-right: 16px;
  border: 1px solid #141332;
  border-radius: 4px;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.cp > div:nth-child(2) {
  position: absolute;
  z-index: 100;
  transform: translate(60px);
  transition: all 0.6s ease-in;
}

.cp--hide {
  display: none;
}

.cp .input_rgba-hex {
  display: flex;
  flex-direction: row-reverse;
  padding: 0 8px 0 60px;
  align-items: right;
}

.cp .input_rgba-hex-label {
  transform: translate(63px, 2px);
  color: #141332 !important;
}

.cp .input_rgba-hex input {
  border-radius: 20px;
  border: 1px solid #141332 !important;
  outline: none;
}

.cp .input_rgba-label {
  display: none;
}

.cp .default-color-panel_item {
  border-radius: 100%;
}

.cp .default-color-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.cp .cp-color {
  position: absolute;
  width: 20px;
  z-index: 100;
  width: 40px;
  height: 40px;
  transform: translate(25px, -150px);
  border-radius: 100%;
  border: 2px solid #141332;
}

.cp .input_rgba-alpha {
  display: none;
}

.add-project__bottom-display {
  margin-left: 24px;
}

.add-project__bottom-display > h2 {
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #9b9b9b;
  margin-bottom: 8px;
}

.add-project__bottom-display-top {
  display: flex;
  flex-direction: column;
  padding: 19px 20px;
  width: 398px;
  background: #ffffff;
  border-radius: 12px;
}

.add-project__bottom-display-top--shadow {
  box-shadow: 0px 6px 16px 2px rgba(0, 0, 0, 0.16);
}

.add-project__bottom-display-top__dets {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.add-project__bottom-display-top__dets img {
  margin-right: 12px;
  object-fit: cover;
}

.add-project__bottom-display-top__dets > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 0 15px;
}

.add-project__bottom-display-top__dets > div > * {
  margin-block: 0;
}

.add-project__bottom-display-top__dets > div > h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #141332;
  margin-bottom: 12px;
}

.add-project__bottom-display-top__dets > div > p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 132%;
  color: #333333;
}

.add-project__bottom-display-top__dets > div p:not(:last-child) {
  margin-bottom: 12px;
}

.add-project__bottom-display-top__audios {
  display: flex;
  align-items: center;
}

.add-project__bottom-display-top__audios > * {
  cursor: default !important;
}

.add-project__bottom-display-top__audios--icons > * {
  cursor: default !important;
}

.add-project__bottom-display-top__audios--alter > svg {
  margin-left: 10px;
}

.add-project__bottom-display-top__audios--icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-project__bottom-display-top__audios--icons > svg {
  cursor: default;
}

.add-project__bottom-display-top__audios--icons > svg:nth-child(4) {
  margin-right: 16px;
}

.add-project__bottom-display-top__audios--icons > div {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #9b9b9b;
  opacity: 0.8;
}

.add-project__bottom-display-top__audios > button {
  padding: 8px 16px;
  background: #3864ff;
  border-radius: 8px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.add-project__bottom-display-top__audios > button > svg {
  margin-right: 12px;
}

.add-project__delete hr {
  margin-top: 40px;
}

.add-project__delete > div {
  margin-top: 30px;
  margin-bottom: 8px;
  display: flex;
}

.add-project__delete > div > button {
  margin-right: 20px;
  background: linear-gradient(314.42deg, #ff0000 21.63%, #ff3333 90.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  border-color: #ff3333 !important;
}

.add-project__delete > div > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.add-project__delete > div > div > span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #9b9b9b;
}

.add-project__bottom-display-top__audios--icons > * {
  margin-right: 10px;
}

.add-project__bottom-display-top__dets__img-control {
  width: 120px !important;
  height: 120px;
  box-sizing: border-box;
}

.add-project__bottom-display-top__dets__img-control > img {
  width: 120px;
}

@media only screen and (max-width: 1650px) {
  .add-project__middle-img__bg-up div:nth-child(1) {
    width: 400px;
  }

  .add-project__middle-dets {
    align-items: flex-start;
    flex-direction: column-reverse;
  }

  .add-project__middle-dets > .add-project__middle-inps {
    width: 100%;
  }

  .add-project__middle-dets > div:nth-child(2) {
    margin-bottom: 15px;
  }

  .add-project__middle-opts > div:nth-child(1) {
    flex-direction: column;
    align-items: flex-start;
  }

  .add-project__middle-opts > div:nth-child(1) > small {
    margin-bottom: 10px;
  }

  .add-project__middle-opts > div:nth-child(1) > .tab-navs div {
    justify-content: center;
    text-align: center;
  }

  /* .add-project__bottom {
    flex-direction: column;
  } */

  .add-project__bottom-selections {
    width: 255px;
  }

  .add-project__bottom-selections__embed > div {
    width: 250px;
  }

  .add-project__bottom-display {
    margin-left: 0;
  }
}

@media only screen and (max-width: 1500px) {
  .add-project__bottom-selections {
    width: 250px;
  }

  .add-project__bottom-selections__embed > div {
    width: 250px;
  }

  .add-project__bottom-display-top {
    width: 290px;
    padding: 8px 10px;
  }

  .add-project__bottom-display-top__dets__img-control {
    width: 80px !important;
    height: 80px;
    box-sizing: border-box;
  }

  .add-project__bottom-display-top__dets__img-control > img {
    width: 80px;
  }
}

@media only screen and (max-width: 1350px) {
  .add-project__bottom-display-top {
    width: 300px;
  }
}

@media only screen and (max-width: 1129px) {
  .add-project__bottom-display-top {
    width: 398px;
    padding: 8px 10px;
  }

  .add-project__bottom-display-top__dets__img-control {
    width: 120px !important;
    height: 120px;
    box-sizing: border-box;
  }

  .add-project__bottom-display-top__dets__img-control > img {
    width: 120px;
  }
}

@media only screen and (max-width: 1008px) {
  .add-project__top-actions > h2,
  .add-project__top-actions > input {
    font-size: 20px;
  }
}

@media only screen and (max-width: 825px) {
  .add-project__bottom-display-top {
    width: 330px;
  }
}

@media only screen and (max-width: 800px) {
  .add-project__bottom-display-top {
    width: 260px;
    padding: 8px 10px;
  }

  .add-project__bottom-display-top__dets__img-control {
    width: 80px !important;
    height: 80px;
    box-sizing: border-box;
  }

  .add-project__bottom-display-top__dets__img-control > img {
    width: 80px;
  }
}

@media only screen and (max-width: 700px) {
  /* .add-project__bottom-display-top {
    width: 200px;
    padding: 8px 10px;
  }

  .add-project__bottom-display-top__dets__img-control {
    width: 80px !important;
    height: 80px;
    box-sizing: border-box;
  }

  .add-project__bottom-display-top__dets__img-control > img {
    width: 80px;
  } */

  .add-project__bottom-selections {
    width: 150px;
  }

  .add-project__bottom-selections__embed > div {
    width: 200px;
  }
}

@media only screen and (max-width: 641px) {
  .add-project__top-actions > h2,
  .add-project__top-actions > input {
    font-size: 18px;
  }

  .add-project__middle-img__bg-up div:nth-child(1) {
    width: 200px;
  }

  .add-project__middle-opts > div:nth-child(1) > .tab-navs div {
    padding: 4px 8px;
  }

  .add-project__bottom-display-top {
    width: 350px;
    margin-right: 0;
  }

  .add-project__bottom-display-top {
    margin-bottom: 30px;
  }

  .add-project__top-actions > input {
    display: none;
  }

  .add-project__delete hr {
    margin-top: 10px;
  }

  .add-project__bottom-display-top {
    width: 250px;
    padding: 8px 10px;
  }

  .add-project__bottom-display-top__dets__img-control {
    width: 80px !important;
    height: 80px;
    box-sizing: border-box;
  }

  .add-project__bottom-display-top__dets__img-control > img {
    width: 80px;
  }
}

@media only screen and (max-width: 615px) {
  .add-project__delete button {
    font-size: 13px;
    padding: 4px 10px;
    margin-right: 10px !important;
  }

  .add-project__delete small {
    font-size: 11px;
  }
}

@media only screen and (max-width: 590px) {
  .add-project__middle-opt .cp > div:nth-child(2) > .popup_tabs {
    min-width: 170px;
    width: 170px !important;
  }
  .add-project__middle-opt
    .cp
    > div:nth-child(2)
    > .popup_tabs
    .popup_tabs-body {
    width: 100%;
  }
  .add-project__middle-opt
    .cp
    > div:nth-child(2)
    > .popup_tabs
    .popup_tabs-body
    .colorpicker {
    width: 82%;
    min-width: 82%;
  }
  .add-project__middle-opt
    .cp
    > div:nth-child(2)
    > .popup_tabs
    .popup_tabs-body
    .colorpicker
    .color-picker-panel {
    width: 100%;
    min-width: 100%;
  }
  .add-project__middle-opt .cp .default-color-panel {
    display: none;
  }
  .add-project__middle-opt .cp .cp-color {
    width: 25px;
    height: 25px;
    transform: translate(12px, -61px);
  }
  .add-project__middle-opt .cp .input_rgba-hex input {
    height: 25px;
    transform: translate(-22px, -10px);
    width: 100%;
    border-radius: 5px;
    padding-left: 16px;
  }
  .add-project__middle-opt .cp .input_rgba-hex-label {
    transform: translate(30px, -15px);
    z-index: 1000;
  }

  .add-project__bottom-display-top {
    width: 340px;
    padding: 19px 20px;
  }

  .add-project__bottom-display-top__dets__img-control {
    width: 120px !important;
    height: 120px;
    box-sizing: border-box;
  }

  .add-project__bottom-display-top__dets__img-control > img {
    width: 120px;
  }

  .add-project__bottom {
    flex-direction: column;
  }
}

@media only screen and (max-width: 480px) {
  .add-project__middle-opt > .cp > div:nth-child(2) {
    transform: translate(-110px, 150px);
  }

  .add-project__delete > div:nth-child(2) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .add-project__delete > div > button {
    max-width: 120px !important;
    margin-bottom: 15px;
  }

  .add-project__delete small {
    text-align: center;
  }
}

@media only screen and (max-width: 470px) {
  .add-project__middle-img__bg-up div:nth-child(1) {
    width: 150px;
  }

  .add-project__middle-opts > div:nth-child(1) > .tab-navs div {
    padding: 4px 6px;
  }

  .add-project__bottom-display-top {
    width: 260px;
  }

  .add-project__bottom-display-top__dets > img {
    width: 80px;
  }

  .add-project__bottom-selections__embed > div {
    width: 280px;
  }

  .add-project__bottom-selections__embed > div > div {
    width: 200px;
  }

  .add-project__bottom-display-top__audios > button > svg {
    display: none;
  }

  .add-project__bottom-display-top__dets > div p:nth-child(3) {
    font-size: 10px;
  }

  .add-project__separator small {
    font-size: 11px;
  }

  .add-project__bottom-display-top__audios--icons > *:not(:last-child) {
    margin-right: 6px;
  }

  .add-project__bottom-display-top {
    width: 280px;
    padding: 12px 12px;
  }

  .add-project__bottom-display-top__dets__img-control {
    width: 100px !important;
    height: 100px;
    box-sizing: border-box;
  }

  .add-project__bottom-display-top__dets__img-control > img {
    width: 100px;
  }
}

@media only screen and (max-width: 380px) {
  .add-project__top {
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .add-project__top--alter {
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .add-project__top--alter .add-project__top-actions > div:nth-child(1) > svg {
    margin-right: 5px;
  }

  .add-project__top--alter .add-project__top-actions > h2 {
    margin-right: 5px;
  }

  .add-project__top--alter > div:nth-child(2) > button {
    padding: 3px 14px !important;
    font-size: 12px !important;
  }

  .add-project__top-actions > h2 {
    margin-right: 0px;
  }

  .add-project__top > div:nth-child(2) > button {
    /* padding: 4px 16px !important; */
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .add-project__middle-img {
    margin-top: -30px !important;
    width: 100%;
  }

  .add-project__middle-img__bg {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .add-project__middle-inps input {
    width: 100% !important;
    box-sizing: border-box;
  }

  .add-project__middle-inps textarea {
    width: 100% !important;
    box-sizing: border-box;
  }

  .add-project__middle-opts > .add-project__middle-opt:nth-child(1) {
    flex-direction: column;
  }

  .add-project__middle-opts > .add-project__middle-opt:nth-child(1) .tab-navs {
    flex-wrap: wrap;
    flex-grow: 0;
    flex-direction: row;
  }

  .add-project__middle-opts
    > .add-project__middle-opt:not(:first-child)
    > small {
    width: 85px;
    margin-right: 10px;
  }

  .add-project__middle-opts
    > .add-project__middle-opt:not(:first-child)
    > .tab-navs {
    padding: 4px;
  }

  .add-project__middle-opts
    > .add-project__middle-opt:not(:first-child)
    > .tab-navs
    > div {
    padding: 2.5px 7px;
    font-size: 12px !important;
  }

  .add-project__middle-opt .MuiSwitch-thumb {
    width: 18px !important;
    height: 18px !important;
  }

  .add-project__middle-opt .MuiSwitch-track {
    height: 25px;
    width: 55px !important;
  }

  .add-project__bottom-selections {
    width: 100%;
  }

  /* .add-project__bottom-selections__embed div:nth-child(2) {
    width: 100%;
    box-sizing: border-box;
    padding: 5px 7px;
    height: 100%;
  }

  .add-project__bottom-selections__embed div:nth-child(2) > div {
    width: 100%;
    text-align: justify;
    padding-right: 5px;
  }

  .add-project__bottom-selections__embed div:nth-child(2) > svg {
    margin-left: 5px;
    width: 24px;
    height: 24px;
  } */

  .add-project__bottom-selections__embed > div {
    width: 215px;
  }

  .add-project__bottom-selections__embed > div > div {
    width: 170px;
  }

  .add-project__bottom-selections__opts .add-project__middle-opt small {
    width: 100px;
    margin-right: 10px;
  }

  .add-project__bottom-selections__opts .tab-navs {
    padding: 4px;
  }

  .add-project__bottom-selections__opts .tab-navs > div {
    padding: 2.5px 7px;
    font-size: 12px !important;
  }

  .add-project__bottom-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .add-project__bottom-display > h2 {
    align-self: flex-start;
  }

  .add-project__bottom-display > div:nth-child(3) {
    margin-bottom: 20px !important;
  }

  .add-project__bottom-display-top {
    padding: 7px;
    margin-bottom: 0;
    /* width: 100%; */
  }

  .add-project__middle-img__bg-wrapper > img {
    margin-right: 0;
  }

  .add-project__middle-img__bg-up {
    align-items: center;
    justify-content: center;
  }

  .add-project__bottom-display-top > .add-project__bottom-display-top__dets {
    flex-direction: column;
    margin-bottom: 8px;
  }

  .add-project__bottom-display-top
    > .add-project__bottom-display-top__dets
    > img {
    margin-right: 0;
  }

  .add-project__bottom-display-top
    > .add-project__bottom-display-top__dets
    > div
    > h3 {
    font-size: 14px;
    margin-bottom: 0px;
  }

  .add-project__bottom-display-top
    > .add-project__bottom-display-top__dets
    > div
    > p {
    font-size: 10px;
    margin-bottom: 1px;
    width: 100%;
  }

  .add-project__separator {
    display: none;
  }

  .add-project__bottom {
    margin-top: -30px;
  }

  .add-project__middle-opt svg {
    display: none;
  }

  .add-project__middle-opt .cp > div {
    margin-left: 0px;
    transform: translateY(-4px);
    margin-right: 0;
  }

  .add-project__bottom-display-top__audios button {
    padding: 4px 8px;
    font-size: 12px;
  }

  .add-project__delete {
    margin-top: 20px;
  }

  .add-project__middle-img__bg-wrapper > img {
    margin-bottom: 10px;
  }

  .add-project__bottom-display-top {
    width: 200px;
    padding: 8px 8px;
  }

  .add-project__bottom-display-top__dets__img-control {
    width: 70px !important;
    height: 70px;
    box-sizing: border-box;
  }

  .add-project__bottom-display-top__dets__img-control > img {
    width: 70px;
  }
}

@media only screen and (max-width: 340px) {
  .add-project__bottom-display-top {
    width: 200px;
    box-sizing: border-box;
  }

  .add-project__middle-img__bg-up div:nth-child(1) {
    width: 100px;
  }
}

.text {
  display: block;
  width: 15rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}