.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-brand {
  font-family: "Josefin Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  cursor: pointer;
}

.header-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-actions * {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  text-decoration: none;
}

.header-actions a {
  margin-right: 32px;
}

.header-actions--span {
  display: inline-block;
  width: 16px;
}

.header-logged-in * {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  text-decoration: none;
}

.header-logged-in {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-logged-in > a,
.header-logged-in > p {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 1rem;
}

.header-logged-in > a:nth-child(1) {
  margin-right: 24px;
  color: #fff;
  fill: #fff;
}

.header-logged-in > a:nth-child(1) > svg {
  width: 1em;
  height: 1em;
  margin-right: 5px;
}

.header-logged-in > a:nth-child(2) {
  margin-right: 24px;
}

.header-small-drawer div:nth-child(3) {
  background-color: #25244a;
}

@media only screen and (max-width: 1008px) {
  .header-brand,
  .header-brand > div {
    font-size: 28px;
  }

  .header-drawer__box-logo {
    font-size: 24px;
  }

  .header-logged-in *,
  .header-actions * {
    font-size: 18px;
  }
}

@media only screen and (max-width: 641px) {
  .header-brand,
  .header-brand > div {
    font-size: 24px;
  }

  .header-drawer__box-logo {
    font-size: 20px;
  }

  .header-logged-in *,
  .header-actions * {
    font-size: 16px;
  }
}

@media only screen and (max-width: 800px) {
  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .header-brand {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-brand > div {
    font-family: "Josefin Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 32px;
    cursor: pointer;
  }

  .header-brand > svg {
    cursor: pointer;
  }

  .header-drawer__box {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #ffffff;
  }

  .header-drawer__box-logo {
    font-family: "Josefin Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
  }

  .header-drawer__box-logo span {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .header-drawer__box-button {
    cursor: pointer;
  }
}

.header-logo{
  padding-left: 5rem;
}