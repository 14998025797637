.popup {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.popup-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 270px;
  box-sizing: border-box;
}

.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-content--header {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
}

.popup-content > hr {
  width: 100%;
}

.popup-content--header-content {
  margin-top: 15px;
}

.popup-content--close {
  transform: translate(-3px, -15px);
  cursor: pointer;
  position: absolute;
  right: 0;
}

.popup-content--close > svg {
  font-size: 30px;
}

.popup-content--details {
  margin-top: 5px;
}

.popup-content--details > span {
  border: 1px solid grey;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;
}
.popup-btn {
  background-color: #4a44fa;
  border-radius: 3px;
  border: 1px solid #6b74c5;
    box-shadow: rgba(198, 221, 254, 0.7) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", "Liberation Sans",
    sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding: 8px 0.8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
  margin-right: 5%;
}

.popup-btn:hover,
.popup-btn:focus {
  background-color: #6492f8;
    color: #2c237b;
}

.popup-btn:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, 0.15);
}

.popup-btn:active {
  background-color: #59a4de;
  box-shadow: none;
  color: #2c3677;
}

.popup-text {
  margin-top: 0%;
}
.popup-btns {
  display: flex;
  margin-bottom: 1.5rem;
}
