.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home__top-img {

  -webkit-user-drag: none !important;
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  width: 100%;
}

.home__top-img__wrapper {
  position: relative;
  width: 100%;
}

.home__top-img__wrapper > div {
  position: absolute;
  text-align: center;
  width: 100%;
  transform: translateY(-40px);
  z-index: 1000;
}

.home__top-img__wrapper > div > h1 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 63px;
  color: #ffffff;
}

.home__top-img__wrapper > div > p {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.home__top-img__wrapper > div > h1 > svg {
  margin: 0 6px;
  transform: translate(1px, 2px);
}

.home__voice-vibration {
  margin: 70px 0;
}

.home-action-content {
  margin-top: 140px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-action-content h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 63px;
  color: #ffffff;
  margin-bottom: 42px;
}

.home-action-content .home-action-content__btns {
  margin-bottom: 42px;
}

.home-action-content > .home-action-content__btns > button:nth-child(1) {
  margin-right: 16px;
}

.home-action-content small {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #d3d3d3;
}

.home-action-content small > span {
  text-decoration: underline;
  cursor: pointer;
}

.home-swipe {
  width: 150%;
}

.home__info-img__wrapper {
  position: relative;
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(121, 113, 219, 0.04);
  border-radius: 16px;
  padding: 85px 115px;
  box-sizing: border-box;
  height: auto;
}

.home__info-img__wrapper .home__info-img {
  width: 100%;
}

.home__info-img__wrapper > p:nth-child(1) {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  margin-block: 0;
  display: inline-block;
  line-height: 42px;
  color: #ffffff;
  margin-block-end: 60px;
  text-align: center;
}

.home__info-img__wrapper > p:nth-child(3) {
  font-style: italic;
  font-weight: 400;
  font-size: 22px;
  display: inline-block;
  margin-block: 0;
  line-height: 33px;
  color: #ffffff;
  text-align: center;
  margin-block-start: 60px;
}

@media only screen and (max-width: 1850px) {
  .home__top-img {
    width: 120%;
    transform: translateX(-8%);
  }
  .home__info-img {
    width: 100%;
  }

  .home__top-img__wrapper > div:nth-child(1) > h1 svg {
    font-size: 36px;
    transform: translateY(-2px);
  }
}

@media only screen and (max-width: 1400px) {
  .home__top-img__wrapper > div {
    transform: translateY(-20px);
  }

  .home__top-img__wrapper > div > h1 {
    font-size: 30px;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  .home__top-img__wrapper > div:nth-child(1) > h1 svg {
    width: 90px;
    transform: translateY(-2px);
  }

  .home__top-img__wrapper > div > p {
    font-size: 18px;
    margin-block-start: 0;
    margin-block-end: 0;
  }
}

@media only screen and (max-width: 1350px) {
  .home__top-img {
    width: 105%;
    transform: translateX(-2%);
  }
}

@media only screen and (max-width: 1008px) {
  .home-action-content h1 {
    font-size: 36px;
  }

  .home__info-img__wrapper > p:nth-child(1) {
    font-size: 24px;
  }

  .home__info-img__wrapper > p:nth-child(3) {
    font-size: 20px;
  }

  .home__info-img__wrapper {
    padding: 70px 40px;
  }
}

@media only screen and (max-width: 900px) {
  .home__top-img__wrapper > div > p {
    transform: translateY(-15px);
  }

  .home__top-img__wrapper > div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .home__top-img__wrapper > div > h1 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
  }

  .home__top-img__wrapper > div > p {
    font-size: 18px;
  }

  .home__top-img {
    margin-top: -50px;
  }
}

@media only screen and (max-width: 800px) {
  .home__voice-vibration {
    width: 55px;
    height: 220px;
  }

  .home__top-img__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .home__top-img__wrapper > div {
    transform: translateY(30px);
  }

  .home__top-img {
    margin-top: 40px;
    width: 100% !important;
    transform: translate(0, 0) !important;
  }
}

@media only screen and (max-width: 700px) {
  .home__info-img__wrapper > p:nth-child(1) {
    font-size: 20px;
  }

  .home__info-img__wrapper > p:nth-child(3) {
    font-size: 18px;
  }

  .home__info-img__wrapper {
    padding: 50px 30px;
  }
}

@media only screen and (max-width: 641px) {
  .home-action-content h1 {
    font-size: 28px;
  }

  .home__top-img__wrapper > div:nth-child(1) > h1 svg {
    width: 80px;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 500px) {
  .home__top-img__wrapper > div > h1 {
    font-size: 22px;
  }

  .home__top-img__wrapper > div > p {
    font-size: 16px;
  }

  /* .home__info-img__wrapper > p:nth-child(1) {
    font-size: 16px;
  }

  .home__info-img__wrapper > p:nth-child(3) {
    font-size: 12px;
  } */

  .home__top-img__wrapper > div:nth-child(1) > h1 svg {
    width: 75px;
    transform: translateY(-1px);
  }
}

@media only screen and (max-width: 450px) {
  .home-action-content h1 {
    font-size: 36px;
  }
  .home-action-content__btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .home-action-content__btns > button:nth-child(1) {
    margin-right: 0 !important;
    margin-bottom: 10px;
  }
  .home-action-content__btns button {
    min-width: 180px;
    width: 85%;
  }

  /* .home__info-img__wrapper > p:nth-child(1) {
    font-size: 14px;
  }

  .home__info-img__wrapper > p:nth-child(2) {
    font-size: 10px;
  } */
}

@media only screen and (max-width: 400px) {
  .home__top-img__wrapper > div > h1 {
    font-size: 18px;
  }
  .home__top-img__wrapper > div > p {
    font-size: 12px;
  }

  .home__top-img {
    width: 90% !important;
  }

  .home__top-img__wrapper > div:nth-child(1) > h1 svg {
    width: 75 px;
    transform: translateY(0);
  }

  .home__info-img__wrapper > p:nth-child(1) {
    font-size: 16px;
    margin-block-end: 30px;
    line-height: 20px;
  }

  .home__info-img__wrapper > p:nth-child(3) {
    font-size: 12px;
    margin-block-start: 30px;
    line-height: 20px;
  }

  .home__info-img__wrapper {
    padding: 30px 10px;
    width: 90%;
  }
}

@media only screen and (max-width: 340px) {
  .home-action-content h1 {
    font-size: 28px;
  }
  .home-action-content small {
    font-size: 13px;
  }
  .home__top-img__wrapper > div > h1 {
    font-size: 16px;
  }
  .home__top-img__wrapper > div > p {
    font-size: 10px;
  }
}

@media only screen and (max-width: 270px) {
  .home__top-img__wrapper > div > h1 {
    font-size: 13px !important;
    margin-block: 0 !important;
    height: 25px;
    margin-top: -30px !important;
  }
  .home__top-img__wrapper > div > h1 svg {
    width: 50px !important;
    height: 20px;
  }

  .home__top-img__wrapper > div p {
    margin-top: 5px;
  }

  .home__top-img {
    margin-top: 20px;
  }
}

/* <style> */
.bee-popup-container div,
.bee-popup-container form,
.bee-popup-container input {
  margin: 0;
  padding: 0
}

.bee-popup-container input {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit
}

.bee-popup-container {
  color: #000;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif
}

.bee-popup-container * {
  box-sizing: border-box
}

.bee-popup-container .bee-popup-row {
  position: relative
}

.bee-popup-container .bee-popup-row-content {
  max-width: 500px;
  position: relative;
  margin: 0 auto;
  display: flex
}

.bee-popup-container .bee-popup-row-content .bee-popup-col-w12 {
  flex: 12
}

.bee-popup-row-1 .bee-popup-col-1 .bee-popup-block-1 .bee-popup-button-container {
  text-align: center
}

.bee-popup-row-1 .bee-popup-col-1 .bee-popup-block-1 .bee-popup-form-row:not(.bee-popup-sidelabel) input:not([type=checkbox]):not([type=radio]) {
  width: 100%
}

.bee-popup-form .bee-popup-form-row .bee-popup-field {
  padding: 3px;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center
}

.bee-popup-form .bee-popup-form-row .bee-popup-field input:not([type=checkbox]):not([type=radio]) {
  flex-grow: 1;
  font-family: inherit;
  font-size: inherit
}

.bee-popup-form .bee-popup-form-row .bee-popup-field button {
  font-family: inherit;
  font-size: inherit
}
.bee-popup-form .bee-popup-form-row .bee-popup-field .bee-popup-button-container {
  flex-grow: 1
}

@media (max-width:520px) {
  .bee-popup-form form {
    width: 100% !important
  }

  .bee-popup-row-content:not(.no_stack) {
    display: block;
    max-width: 250px
  }
}

.bee-popup-row-1 {
  background-repeat: no-repeat
}

.bee-popup-row-1 .bee-popup-row-content {
  background-repeat: no-repeat;
  color: #000
}

.bee-popup-row-1 .bee-popup-col-1 {
  padding-bottom: 5px;
    padding-top: 5px
}

.bee-popup-row-1 .bee-popup-col-1 .bee-popup-block-1 {
  font-family: inherit;
  font-size: 14px;
  padding: 10px;
  text-align: center
}

.bee-popup-row-1 .bee-popup-col-1 .bee-popup-block-1 form {
  display: inline-block;
  width: 100%
}

.bee-popup-row-1 .bee-popup-col-1 .bee-popup-block-1 div.label,
.bee-popup-row-1 .bee-popup-col-1 .bee-popup-block-1 label {
  color: #000;
  line-height: 200%;
  text-align: left;
  display: block
  }

  .bee-popup-row-1 .bee-popup-col-1 .bee-popup-block-1 div.label:not(.inline),
  .bee-popup-row-1 .bee-popup-col-1 .bee-popup-block-1 label:not(.inline) {
    flex-basis: 100%
}

.bee-popup-row-1 .bee-popup-col-1 .bee-popup-block-1 ::-webkit-input-placeholder {
  color: #000
}

.bee-popup-row-1 .bee-popup-col-1 .bee-popup-block-1 :-moz-placeholder {
  color: #000
}

.bee-popup-row-1 .bee-popup-col-1 .bee-popup-block-1 ::-moz-placeholder {
  color: #000
}

.bee-popup-row-1 .bee-popup-col-1 .bee-popup-block-1 :-ms-input-placeholder {
  color: #000
}

.bee-popup-row-1 .bee-popup-col-1 .bee-popup-block-1 .bee-popup-field>label:first-child,
.bee-popup-row-1 .bee-popup-col-1 .bee-popup-block-1 div.label {
  padding: 3px
}

.bee-popup-row-1 .bee-popup-col-1 .bee-popup-block-1 input:not([type=checkbox]):not([type=radio]):not([type=image]) {
  background-color: #fff;
  border-bottom: 1px solid #ade;
    border-left: 1px solid #ade;
    border-radius: 4px;
    border-right: 1px solid #ade;
    border-top: 1px solid #ade;
    color: #000;
  padding: 5px;
  min-height: 38px
}

.bee-popup-row-1 .bee-popup-col-1 .bee-popup-block-1 input:not([type=checkbox]):not([type=radio]):focus {
  outline-color: #3aaee0
}

.bee-popup-row-1 .bee-popup-col-1 .bee-popup-block-1 button {
  background-color: #3aaee0;
  border-bottom: 0 solid transparent;
    border-left: 0 solid transparent;
  border-radius: 4px;
  border-right: 0px solid transparent;
  border-top: 0 solid transparent;
  color: #fff;
  line-height: 200%;
  padding: 5px 20px;
    width: 100%
}
/* </style> */
