.account-left {
  position: sticky;
  min-width: 260px;
  box-sizing: border-box;
  height: 686px;
  background: #25244a;
  border-radius: 28px;
  margin-right: 35px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.account-left button {
  background-color: transparent;
  border: none !important;
  outline: none !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #d3d3d3;
  box-sizing: border-box;
  cursor: pointer;
  padding: 12px 24px;
  box-sizing: border-box;
  border-radius: 14px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.account-left--active button {
  background-color: #3864ff !important;
  color: #fff !important;
  opacity: 1 !important;
}

.account-left--active button * {
  color: #ffffff !important;
  opacity: 1 !important;
}

.account-left__all-recordings {
  width: 100%;
  margin-bottom: 14px;
  box-sizing: border-box;
}

.account-left__projects > div:nth-child(1) {
  width: 100%;
  margin-bottom: 13px;
  box-sizing: border-box;
}

.account-left__projects > div:nth-child(1) button {
  display: flex;
  align-items: center;
}

.account-left__projects > div:nth-child(1) span {
  margin-right: 12px;
}

.account-left__projects > div:nth-child(2) > div {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #d3d3d3;
  margin-bottom: 20px;
  height: 40px;
  margin-left: 16px;
  box-sizing: border-box;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.account-left-div--active {
  background: #3864ff !important;
  color: #fff !important;
  border-radius: 12px;
}

.account-left-div--active > div {
  color: #fff !important;
}

.account-left__projects > div:not(:first-child) button {
  font-size: 16px;
}

.account-left__projects-list--hide {
  transition: all 0.8s ease-in;
  display: none !important;
}

.account-left__projects-list {
  transition: all 0.8s ease-in;
  display: block;
}

.account-left__projects-list__items > div {
  max-width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.account-left__add {
  padding-left: 24px;
  line-height: 21px;
  color: #d3d3d3;
  opacity: 0.7;
}

.account-left__add button {
  font-weight: 500 !important;
  width: 178px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  color: #fff !important;
  padding: 8px 14px !important;
  background-color: #393865 !important;
  border-radius: 12px !important;
}

.account-left__add button span {
  margin-left: 6px !important;
}

.account-left__bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.account-left--small {
  width: 258px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.account-left--small button {
  background-color: transparent;
  border: none !important;
  outline: none !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #d3d3d3;
  box-sizing: border-box;
  cursor: pointer;
  padding: 12px 24px;
  box-sizing: border-box;
  border-radius: 14px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.account-left--active button {
  background-color: #3864ff !important;
  color: #ffffff !important;
  opacity: 1 !important;
}

.account-left--active button * {
  color: #ffffff !important;
  opacity: 1 !important;
}

.account-left__all-recordings {
  width: 100%;
  margin-bottom: 14px;
  box-sizing: border-box;
}

.account-left__projects > div:nth-child(1) {
  width: 100%;
  margin-bottom: 13px;
  box-sizing: border-box;
}

.account-left__projects > div:nth-child(1) button {
  display: flex;
  align-items: center;
}

.account-left__projects > div:nth-child(1) span {
  margin-right: 12px;
}

.account-left__projects > div:nth-child(2) > div {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #d3d3d3;
  margin-bottom: 20px;
  height: 40px;
  margin-left: 25px;
  box-sizing: border-box;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.account-left__projects > div:not(:first-child) button {
  font-size: 16px;
}

.account-left__projects-list--hide {
  transition: all 0.8s ease-in;
  display: none !important;
}

.account-left__projects-list {
  transition: all 0.8s ease-in;
  display: block;
  max-height: 185px;
  overflow-y: auto;
  margin-bottom: 20px;
  padding-right: 15px;
}

.account-left__projects ::-webkit-scrollbar {
  width: 2px !important;
  cursor: pointer;
}

.account-left__projects ::-webkit-scrollbar-thumb {
  background: #bdbdbd !important;
  border-radius: 24px;
  cursor: pointer;
}

.account-left__add button span {
  margin-left: 10px;
}

.account-left__users {
  margin-top: 14px;
  width: 100%;
  margin-bottom: 14px;
  box-sizing: border-box;
}

@media only screen and (max-width: 1008px) {
  .account-left button {
    font-size: 18px;
  }
}

@media only screen and (max-width: 641px) {
  .account-left button {
    font-size: 16px;
  }
}

@media only screen and (max-width: 350px) {
  .account-left--small {
    width: 230px;
  }

  .all-recs__head > div:nth-child(2) > svg {
    margin-right: 10px !important;
  }

  .all-recs__head > div:nth-child(2) > div:nth-child(2) > button {
    margin-right: 15px !important;
    padding: 4px 16px !important;
    width: auto !important;
    height: auto !important;
    border-radius: 5px !important;
  }

  .all-recs__head > div:nth-child(2) > div:nth-child(3) > .tab-navs {
    padding: 4px;
  }

  .all-recs__head > div:nth-child(2) > div:nth-child(3) > .tab-navs > div {
    padding: 2px 7px;
    font-size: 12px;
  }

  .all-recs__head > div:nth-child(2) > div:nth-child(3) > .tab-navs svg {
    width: 17px !important;
  }

  .audio-stick__icons {
    position: relative !important;
    right: auto !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    width: 100% !important;
    padding: 0 7px;
    box-sizing: border-box;
    margin-top: -15px;
  }

  .audio-stick__icons svg {
    margin-bottom: 0 !important;
  }

  .audio-stick__audio .webform-audio__wave {
    width: 85% !important;
  }

  .audio-stick hr {
    margin: 15px 0 !important;
  }
}
