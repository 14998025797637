.auth {
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth--align {
  min-width: 600px;
  min-height: 594px;
  width: 50%;
}

.auth-tab-menu {
  width: 100%;
  height: 100%;
  background-color: #ffffff !important;
  color: #141332;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 28px;
  position: relative;
}

.auth-tab-menu--btns {
  width: 100%;
  display: flex;
}

.auth-tab-menu--btns div {
  min-width: 300px;
  min-height: 67px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #828282;
  background: #f2f2f2;
  cursor: pointer;
}

.auth-tab-menu--btns div:first-child {
  border-top-left-radius: 28px;
}

.auth-tab-menu--btns div:last-child {
  border-top-right-radius: 28px;
}

.auth-tab-menu--btn-active {
  color: #141332 !important;
  background-color: #ffffff !important;
}

.auth-tab-menu--content {
  margin-top: 42px;
  margin-bottom: 48px;
  min-width: 391px;
  min-height: 300px;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-tab-menu--content input {
  width: 100%;
  padding: 10px 16px;
  background: #f2f2f2;
  border-radius: 8px;
  outline: none;
  border: none;
  color: #141332;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.auth-tab-menu--content-or {
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #828282;
  margin: 24px 0;
}

.auth-tab-menu--content-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.auth-tab__error {
  align-self: flex-start;
  margin-block: 0;
  margin-top: 5px;
  padding-top: 5px;
  color: #ea4e4e;
}

.auth-tab__forget {
  justify-self: flex-start;
  align-self: flex-start;
  color: #3d38ff;
  text-decoration: underline;
  cursor: pointer;
}

.auth-tab__cutom-twitter-btn {
  width: 100%;
}

.auth-overlay {
  position: absolute;
  z-index: 200;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.9s ease-in-out;
  background-color: rgba(1, 1, 1, 0.5);
  border-radius: 25px;
}

.auth-overlay--hide {
  display: none !important;
  transition: all 0.9s ease-in-out;
}

@media only screen and (max-width: 1008px) {
  .auth-tab-menu--btns div,
  .auth-tab-menu--content-or {
    font-size: 18px;
  }
}

@media only screen and (max-width: 641px) {
  .auth-tab-menu--btns div,
  .auth-tab-menu--content-or {
    font-size: 16px;
  }
}

@media only screen and (max-width: 800px) {
  .auth {
    margin: 60px 0;
  }
}

@media only screen and (max-width: 750px) {
  .auth {
    margin: 85px 0;
  }
}

@media only screen and (max-width: 650px) {
  .auth--align {
    min-width: 95%;
    width: 95% !important;
  }

  .auth-tab-menu--btns div {
    min-width: 50%;
    width: 50%;
  }
}

@media only screen and (max-width: 580px) {
  .auth-tab-menu--content {
    min-width: 80%;
    width: 80%;
  }

  .auth-tab-menu--content-form input {
    box-sizing: border-box;
  }
}
